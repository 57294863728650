import { createVuetify, type ThemeDefinition } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import colors from 'vuetify/lib/util/colors.mjs';
import { fa } from "vuetify/iconsets/fa";
import { mdi, aliases } from "vuetify/iconsets/mdi-svg";

const eDarkTheme: ThemeDefinition = {
    dark: true,
    colors: {
        background: '#808080',
        primary: "#4193ea",
        accent: "#00bbff",
        secondary: "#67e6ba",
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
        'on-background': '#2c3e50'
    }
}

export default defineNuxtPlugin(nuxtApp => {
    const vuetify = createVuetify({
        components,
        directives,
        theme: {
            defaultTheme: "eDarkTheme",
            themes: {
                eDarkTheme
            }
        },
        icons: {
            defaultSet: "mdi",
            sets: { fa, mdi },
            aliases
        }
    });

    nuxtApp.vueApp.use(vuetify);
});