import type { NuxtApp } from "#app";

export const loadPartsLocaleMessages = async (locale: string, context: NuxtApp) => {
    const localeMessagesFiles = import.meta.glob("~/parts/**/lang/**/*.json", { import: "default" }); 
    const modules = Object.entries(localeMessagesFiles)
        .filter(module => module[0].includes(`/${locale}/`))
        .map(async module => await module[1]());
    const messagesfiles = await Promise.all(modules);
    messagesfiles.forEach((messages: any) => {
        context.$i18n.mergeLocaleMessage(locale, messages);
    });
    return locale;
}

//export function addAutoImportTypes(context: Nuxt, types: any[]) {
//    types.forEach(t => {
//        const typeDef = {
//            from: t.path,
//            imports: [
//                { name: "default", as: t.name }
//            ]
//        };
//        context.$config.
//    });
//}