import { type Ref } from "vue-demi";
import EService from '~/parts/core/services/eService';

export enum Env {
    Production = "production",
    Developement = "development"
}

export enum InputType {
    Text = "string",
    Number = "number",
    Email = "email",
    Password = "password",
    Select = "select",
    File = "file",
    Dynamic = "dynamic",
    Custom = "custom"
}

export declare interface InputOptionInfo {
    type?: InputType;
    lookup?: {
        typeName?: string; dataSourceKey?: string; items?: Array<any>;
        valueField: string; textField?: string | ((item: any) => string)
    };
    rules?: any;
    selectMode?: string;
    accept?: string;
    allowNull?: boolean;
    [key: string]: any;
}

export declare interface ColumnInfo {
    isKey?: boolean;
    value?: string;
    text?: string;
    textKey?: string;
    visibleOn?: string;
    readOnly?: boolean;
    custom?: { cellTemplateName?: string; editTemplateName?: string; };
    align?: string;
    sortable?: boolean;
    groupable?: boolean;
    inputOption?: InputOptionInfo;
    minWidth?: number;
}

export declare interface TableOptions {
    allowCreate?: boolean;
    allowDelete?: boolean;
    allowEdit?: boolean;
    addItemButtonText?: string;
    title: string;
    newItemTitle?: string;
    editItemTitle?: string;
    lookups?: Ref<{ [key: string]: Array<any> }>;
    validation?: { scope?: string };
    groupBy?: Array<{
        key: string;
        order?: boolean | 'asc' | 'desc';
    }>;
    allowSearchFiltering?: boolean;
    helpers?: {
        getSearchablePropertiesValues: (item: any) => Array<any>
    };
}