import { loadingStore } from "~/stores/loading/e.loading.store";

export const useLoading = () => {
    function display(): void {
        const { displayLoading } = loadingStore();
        displayLoading();
    }

    function hide(hideAll: boolean = false): void {
        const { hideLoading } = loadingStore();
        hideLoading(hideAll);
    }

    async function show(fct: () => Promise<any>): Promise<any> {
        display();
        return await fct().finally(() => {
            hide();
        });
    }

    return { show, display, hide };
} 