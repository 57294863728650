import ReferentialsService from "~/parts/referentials/referentialsService";
import ReferentialWs from "~/parts/referentials/referentialWs";
import { type IHttpConnectionOptions } from "@microsoft/signalr";

export default defineNuxtPlugin(async nuxtApp => {
    const referentialsService = new ReferentialsService();
    await referentialsService.init();


    const config = useRuntimeConfig();
    const referentialWs = new ReferentialWs(config.public);
    const { onSignOut, onSignedIn } = useEAuth();

    const startsWss = () => {
        const { tokens, loggedIn } = useEAuth();
        if (tokens.value?.token && loggedIn.value) {
            const wsOption: IHttpConnectionOptions = { accessTokenFactory: () => tokens.value.token as string };
            referentialWs.start(wsOption);
        }
    };

    startsWss();

    onSignedIn(() => Promise.resolve(startsWss()));
    onSignOut(async () => {
        await referentialWs.stop();
    });

    nuxtApp.vueApp.provide("$referentialWs", referentialWs);

});