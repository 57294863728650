<template>
    <div class="NuxtPwaManifest">
        <NuxtPwaAssets />
        <NuxtPage />
    </div>
</template>
<script setup lang="ts">

    const i18nHead = useLocaleHead({
        addSeoAttributes: true,
        addDirAttribute: true
    });

    useHead({
        htmlAttrs: {
            lang: i18nHead.value.htmlAttrs!.lang,
            dir: i18nHead.value.htmlAttrs!.dir
        }
    });

</script>