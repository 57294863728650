import { type UserAuth, type Tokens } from "~/parts/users";

export declare interface AuthResult {
    error: string | undefined;
    status: number;
    ok: boolean;
    url: string | null;
}

const loggedIncallbacks: Array<() => Promise<void>> = [];
function onSignedIn(callback: () => Promise<void>) {
    loggedIncallbacks.push(callback);
}

async function loginAsync(user: UserAuth, callbackUrl?: string): Promise<AuthResult> {
    const { signIn } = useAuth();
    const conf = useRuntimeConfig();
    const result = await signIn({ email: user.email, password: user.password }, { callbackUrl: callbackUrl });
    const { data } = useAuth();
    const sessionData = data.value as ESessionData;
    const authProvider = conf.public.auth.provider as any;
    const tokenCookie = useCookie(authProvider.token.cookieName);
    tokenCookie.value = sessionData.tokens.token;
    if (isLoggedIn().value) {
        const callbacks = loggedIncallbacks.map(async c => await c());
        await Promise.all(callbacks);
    }
    return result;
}

function isTokenExpired() {
    const { data } = useAuthState();
    const sessionData = data.value as ESessionData;
    const expired = !sessionData?.tokens.tokenExpiresOn || sessionData?.tokens.tokenExpiresOn
        && new Date(sessionData.tokens.tokenExpiresOn) < new Date();
    return ref(expired);
}

function isLoggedIn() {
    const { status, data } = useAuthState();
    const sessionData = data.value as ESessionData;
    const loggedIn = computed(() => status.value == "authenticated"
        && sessionData?.tokens.tokenExpiresOn
        && new Date(sessionData.tokens.tokenExpiresOn) >= new Date());
    return loggedIn;
}

function getCurrentUser(): Ref<User | undefined>{
    if (!isLoggedIn().value)
        return ref(undefined);
     const { data } = useAuthState();
    return computed(() => data.value?.user as User | undefined);
}

function getTokens() {
    if (!isLoggedIn().value)
        return ref(undefined);
    const { data } = useAuthState();
    const sessionData = data.value as ESessionData;
    const tokens = ref(sessionData?.tokens);
    return tokens;
}

async function refreshTokenAsync() {
    const { data, refresh } = useAuth();
    const { post } = useEApi();
    const sessionData = data?.value as ESessionData;
    if (sessionData?.tokens.refreshToken) {
        const tokenData = await post<Tokens, Tokens>(`/authentication/RefreshToken`,
            { refreshToken: sessionData.tokens.refreshToken }, {
            cache: "no-cache"
        });
        if (tokenData?.token) {
            await loginAsync({ tokens: tokenData } );
        } else {
            signOut();
        }
    }
}

const signOutcallbacks: Array<() => Promise<void>> = [];
function onSignOut(callback: () => Promise<void>) {
    signOutcallbacks.push(callback);
}

interface SignOutOptions {
    callbackUrl?: string;
    redirect?: boolean;
    external?: boolean;
}

async function signOut(option?: SignOutOptions) {
    const { signOut: logout, data } = useAuth();
    const { post } = useEApi();
    const callbacks = signOutcallbacks.map(async c => await c());
    await Promise.all(callbacks);
    const config = useRuntimeConfig();
    const authProvider = config.public.auth.provider as any;
    const tokenCookie = useCookie(authProvider.token.cookieName);
    tokenCookie.value = null;
    const sessionData = data?.value as ESessionData;
    if (sessionData?.tokens?.refreshToken) {
        const revokeRefreshTokenPath = `${config.public.auth.baseURL}/revokerefreshtoken`;
        await post(
            revokeRefreshTokenPath,
            { refreshToken: sessionData.tokens.refreshToken },
            { cache: "no-cache" }
        );
    }
    await logout(option);
}

export {
    isLoggedIn,
    loginAsync,
    onSignOut,
    signOut,
    refreshTokenAsync,
    getCurrentUser,
    getTokens,
    isTokenExpired,
    onSignedIn
}
