
// @ts-nocheck


export const localeCodes =  [
  "en",
  "fr"
]

export const localeLoaders = {
  "en": [{ key: "../ClientWebAppSrc/lang/en/admin.json", load: () => import("../ClientWebAppSrc/lang/en/admin.json" /* webpackChunkName: "locale_D_58_D_58_a_1_s_ClientWebApp_v1_ClientWebAppSrc_lang_en_admin_json" */), cache: true },
{ key: "../ClientWebAppSrc/lang/en/database.json", load: () => import("../ClientWebAppSrc/lang/en/database.json" /* webpackChunkName: "locale_D_58_D_58_a_1_s_ClientWebApp_v1_ClientWebAppSrc_lang_en_database_json" */), cache: true },
{ key: "../ClientWebAppSrc/lang/en/pagesTitles.json", load: () => import("../ClientWebAppSrc/lang/en/pagesTitles.json" /* webpackChunkName: "locale_D_58_D_58_a_1_s_ClientWebApp_v1_ClientWebAppSrc_lang_en_pagesTitles_json" */), cache: true },
{ key: "../ClientWebAppSrc/lang/en/global.json", load: () => import("../ClientWebAppSrc/lang/en/global.json" /* webpackChunkName: "locale_D_58_D_58_a_1_s_ClientWebApp_v1_ClientWebAppSrc_lang_en_global_json" */), cache: true }],
  "fr": [{ key: "../ClientWebAppSrc/lang/fr/admin.json", load: () => import("../ClientWebAppSrc/lang/fr/admin.json" /* webpackChunkName: "locale_D_58_D_58_a_1_s_ClientWebApp_v1_ClientWebAppSrc_lang_fr_admin_json" */), cache: true },
{ key: "../ClientWebAppSrc/lang/fr/database.json", load: () => import("../ClientWebAppSrc/lang/fr/database.json" /* webpackChunkName: "locale_D_58_D_58_a_1_s_ClientWebApp_v1_ClientWebAppSrc_lang_fr_database_json" */), cache: true },
{ key: "../ClientWebAppSrc/lang/fr/pagesTitles.json", load: () => import("../ClientWebAppSrc/lang/fr/pagesTitles.json" /* webpackChunkName: "locale_D_58_D_58_a_1_s_ClientWebApp_v1_ClientWebAppSrc_lang_fr_pagesTitles_json" */), cache: true },
{ key: "../ClientWebAppSrc/lang/fr/global.json", load: () => import("../ClientWebAppSrc/lang/fr/global.json" /* webpackChunkName: "locale_D_58_D_58_a_1_s_ClientWebApp_v1_ClientWebAppSrc_lang_fr_global_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "files": [
        "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/en/admin.json",
        "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/en/database.json",
        "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/en/pagesTitles.json",
        "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/en/global.json"
      ],
      "language": "en-US",
      "name": "English"
    },
    {
      "code": "fr",
      "files": [
        "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/fr/admin.json",
        "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/fr/database.json",
        "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/fr/pagesTitles.json",
        "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/fr/global.json"
      ],
      "language": "fr-FR",
      "name": "Français"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "e_app_browser_lang",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": [
    {
      "langDir": "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang",
      "locales": [
        {
          "code": "en",
          "files": [
            "en/admin.json",
            "en/database.json",
            "en/pagesTitles.json"
          ]
        },
        {
          "code": "fr",
          "files": [
            "fr/admin.json",
            "fr/database.json",
            "fr/pagesTitles.json"
          ]
        }
      ]
    }
  ]
}

export const normalizedLocales = [
  {
    "code": "en",
    "files": [
      {
        "path": "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/en/admin.json"
      },
      {
        "path": "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/en/database.json"
      },
      {
        "path": "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/en/pagesTitles.json"
      },
      {
        "path": "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/en/global.json"
      }
    ],
    "language": "en-US",
    "name": "English"
  },
  {
    "code": "fr",
    "files": [
      {
        "path": "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/fr/admin.json"
      },
      {
        "path": "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/fr/database.json"
      },
      {
        "path": "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/fr/pagesTitles.json"
      },
      {
        "path": "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/lang/fr/global.json"
      }
    ],
    "language": "fr-FR",
    "name": "Français"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
