import { refreshTokenAsync } from "~/parts/auth";
import { type GlobalResources as Gr } from "i18n-resources";


export default defineNuxtRouteMiddleware(async (to, from) => {
    const nuxtApp = useNuxtApp();
    let { loggedIn } = await useEAuth();
    const localPath = useLocalePath();
    const { t } = useELocale(nuxtApp.$i18n);
    const config = useRuntimeConfig();
        const requiredPermissions = to.meta.permissions as string
    if (loggedIn.value && requiredPermissions) {
        const { hasAccessAsync } = await useCurrentUser();
        const hasAccess = await hasAccessAsync(requiredPermissions);
        if (!hasAccess) {
            return abortNavigation(t<Gr>(gr => gr.accessDenied));
        }
    } else if (config.public.auth.globalAppMiddleware.isEnabled && to.meta.auth == undefined
        || to.meta.auth
        || to.meta.middleware as string == "auth"
        || (to.meta.middleware as Array<string>)?.includes("auth")) {

        if (!loggedIn.value) {
            const loginPathName = config.public.auth.provider.pages.login;
            const localeLoginPath = localPath({
                name: loginPathName,
                query: { redirect: to.path }
            });
            return navigateTo(localeLoginPath);
        }
    }
});