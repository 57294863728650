import { type TranslateOptions  } from "@intlify/core-base";
import { 
    type I18nOptions,
    type LocaleMessageValue,
    type VueMessageType,
    type Composer
} from "vue-i18n";

type ResourceKey<TKey> = (k: TKey) => any;
export type I18nInstanceType = Composer<
    NonNullable<I18nOptions['messages']>,
    NonNullable<I18nOptions['datetimeFormats']>,
    NonNullable<I18nOptions['numberFormats']>,
    I18nOptions['locale'] extends unknown ? string : I18nOptions['locale']>;

export function useELocale(i18n?: I18nInstanceType, i18nOpt?: I18nOptions) {
    i18n = i18n ?? useI18n(i18nOpt);
    const {
        availableLocales,
        baseUrl,
        d,
        datetimeFormats,
        defaultDirection,
        defaultLocale,
        differentDomains,
        escapeParameter,
        fallbackFormat,
        fallbackLocale,
        fallbackRoot,
        fallbackWarn,
        finalizePendingLocaleChange,
        getBrowserLocale,
        getDateTimeFormat,
        getLocaleCookie,
        getLocaleMessage,
        getMissingHandler,
        getNumberFormat,
        getPostTranslationHandler,
        id,
        inheritLocale,
        isGlobal,
        loadLocaleMessages,
        locale,
        localeCodes,
        localeProperties,
        locales,
        mergeDateTimeFormat,
        mergeLocaleMessage,
        mergeNumberFormat,
        messages,
        missingWarn,
        modifiers,
        n,
        numberFormats,
        onBeforeLanguageSwitch,
        onLanguageSwitched,
        pluralRules,
        rt,
        setDateTimeFormat,
        setLocale,
        setLocaleCookie,
        setLocaleMessage,
        setMissingHandler,
        setNumberFormat,
        setPostTranslationHandler,
        strategy,
        waitForPendingLocaleChange,
        warnHtmlMessage,
        te: teI18n,
        tm: tmI18n,
        t: tI18n
    } = i18n;

    function te<TKey>(key: ResourceKey<TKey>, locale?: string): boolean;
    function te<TKey>(key: ResourceKey<TKey>, locale?: string) {
        return teI18n(nameOf(key) as string, locale);
    }

    function tm<TKey>(key: ResourceKey<TKey>): LocaleMessageValue<VueMessageType>;
    function tm<TKey>(key: ResourceKey<TKey>) {
        return tmI18n(nameOf(key) as string);
    }

    function t<TKey>(key: ResourceKey<TKey> | string, opt?: TranslateOptions<string>): string;
    function t<TKey>(key: ResourceKey<TKey> | string, plural: number, opt?: TranslateOptions<string>): string;
    function t<TKey>(key: ResourceKey<TKey> | string, list: unknown[], opt?: TranslateOptions<string>): string;
    function t<TKey>(key: ResourceKey<TKey> | string, list: unknown[], plural: number): string;
    function t<TKey>(key: ResourceKey<TKey> | string, named: Record<string, unknown>, opt?: TranslateOptions<string>): string;
    function t<TKey>(key: ResourceKey<TKey> | string, named: Record<string, unknown>, plural: number): string;
    function t<TKey>(key: ResourceKey<TKey> | string, pl?: unknown, lst?: unknown, nmd?: unknown, opt?: unknown) {
        const keyString = typeof(key) == "string" ? key : nameOf(key) as string;
        const defaultMsg = `(=${keyString}=)`;
        const opts = opt as TranslateOptions<string>;
        const options = opt && opts.default ? opts : (opts ? { ...opts, default: defaultMsg } : { default: defaultMsg });
        if (pl && lst) {
            return tI18n(keyString, lst as unknown[], pl as number);
        }
        if (pl && nmd) {
            return tI18n(keyString, nmd as Record<string, unknown>, pl as number);
        }
        if (pl) {
            return tI18n(keyString, pl as number, options);
        }
        if (lst) {
            return tI18n(keyString, lst as unknown[], options);
        }
        if (nmd) {
            return tI18n(keyString, nmd as Record<string, unknown>, options);
        }
        return tI18n(keyString, defaultMsg, options);
    }

    return {
        availableLocales,
        baseUrl,
        datetimeFormats,
        defaultDirection,
        defaultLocale,
        differentDomains,
        escapeParameter,
        fallbackFormat,
        fallbackLocale,
        fallbackRoot,
        fallbackWarn,
        finalizePendingLocaleChange,
        getBrowserLocale,
        getDateTimeFormat,
        getLocaleCookie,
        getLocaleMessage,
        getMissingHandler,
        getNumberFormat,
        getPostTranslationHandler,
        id,
        inheritLocale,
        isGlobal,
        loadLocaleMessages,
        locale,
        localeCodes,
        localeProperties,
        locales,
        mergeDateTimeFormat,
        mergeLocaleMessage,
        mergeNumberFormat,
        messages,
        missingWarn,
        modifiers,
        numberFormats,
        onBeforeLanguageSwitch,
        onLanguageSwitched,
        pluralRules,
        rt,
        setDateTimeFormat,
        setLocale,
        setLocaleCookie,
        setLocaleMessage,
        setMissingHandler,
        setNumberFormat,
        setPostTranslationHandler,
        strategy,
        waitForPendingLocaleChange,
        warnHtmlMessage,
        te,
        tm,
        d,
        n,
        t
    };
}