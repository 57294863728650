import { AddFilters } from "~/parts/core/common/filters/filters";

declare global {
    interface Array<T> {
        sortBy(propertyFunc: (x: T) => number, desc?: string): Array<T>;
    }
}

export default defineNuxtPlugin(nuxtApp => {

    Array.prototype.sortBy = function (propertyFunc, desc = "asc") {
        return this.slice(0).sort( (a, b) => {
            return desc == "desc"
                ? propertyFunc(b) - propertyFunc(a)
                : propertyFunc(a) - propertyFunc(b);
        });
    };

    AddFilters(nuxtApp.vueApp);
});
