import { default as indexaQeDO29uLvMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/account/index.vue?macro=true";
import { default as accountw1Y9owfDFiMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/account.vue?macro=true";
import { default as databaseimKSoMZGAtMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/database.vue?macro=true";
import { default as indexfqGlzTgpAUMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/index.vue?macro=true";
import { default as indexI965TYoOcJMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/medias/index.vue?macro=true";
import { default as mediasT4SrFfL0szMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/medias.vue?macro=true";
import { default as monitoringirfjGcWZYPMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/monitoring.vue?macro=true";
import { default as index36Aur5pW4IMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/organisations/index.vue?macro=true";
import { default as memberFL1zKApLrQMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/organisations/member.vue?macro=true";
import { default as organisationsWZRguKM6YsMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/organisations.vue?macro=true";
import { default as accessesL5sJw7QBBdMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users/accesses.vue?macro=true";
import { default as indexjpnnWzJK2rMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users/index.vue?macro=true";
import { default as permissionWD60ao0HQEMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users/permission.vue?macro=true";
import { default as users9Cgf7B4uLuMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users.vue?macro=true";
import { default as admingeQBeoGEK7Meta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin.vue?macro=true";
import { default as loginxj5AzlKiskMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/auth/login.vue?macro=true";
import { default as password_45resetbNFAmQM7JcMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/auth/password-reset.vue?macro=true";
import { default as sing_45upI9WSN6sEt8Meta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/auth/sing-up.vue?macro=true";
import { default as indexZYTcSf8P2qMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/file-transfert/index.vue?macro=true";
import { default as uploadqo65OiMrNvMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/file-transfert/upload.vue?macro=true";
import { default as file_45transfertCEEJeGU6ShMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/file-transfert.vue?macro=true";
import { default as indexQeHho5MbOlMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/index.vue?macro=true";
import { default as indexD4jbcCS0FTMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/medias/index.vue?macro=true";
import { default as videosIVFkStdGmFMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/medias/videos.vue?macro=true";
import { default as mediaseDxfU3SeanMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/medias.vue?macro=true";
import { default as indexVIh0OaQEwkMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring/index.vue?macro=true";
import { default as liveGGrSW6StDMMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring/live.vue?macro=true";
import { default as recordedJt3FZD0AYrMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring/recorded.vue?macro=true";
import { default as monitoringVSXmF0u7w3Meta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring.vue?macro=true";
import { default as _91id_93cVynUEpbXvMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/organisations/[id].vue?macro=true";
import { default as indexfP7vN4CcBSMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/organisations/index.vue?macro=true";
import { default as organisationscf0RPpRFDtMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/organisations.vue?macro=true";
import { default as appYQ1M9TLrvoMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app.vue?macro=true";
import { default as index2Z9I93O6EjMeta } from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/index.vue?macro=true";
export default [
  {
    name: appYQ1M9TLrvoMeta?.name,
    path: "/app",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: accountw1Y9owfDFiMeta?.name,
    path: "account",
    meta: accountw1Y9owfDFiMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/account.vue").then(m => m.default || m),
    children: [
  {
    name: "app-account___en",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/account/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: admingeQBeoGEK7Meta?.name,
    path: "admin",
    meta: admingeQBeoGEK7Meta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "app-admin-database___en",
    path: "database",
    meta: databaseimKSoMZGAtMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/database.vue").then(m => m.default || m)
  },
  {
    name: "app-admin___en",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/index.vue").then(m => m.default || m)
  },
  {
    name: mediasT4SrFfL0szMeta?.name,
    path: "medias",
    meta: mediasT4SrFfL0szMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/medias.vue").then(m => m.default || m),
    children: [
  {
    name: "app-admin-medias___en",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/medias/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app-admin-monitoring___en",
    path: "monitoring",
    meta: monitoringirfjGcWZYPMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/monitoring.vue").then(m => m.default || m)
  },
  {
    name: organisationsWZRguKM6YsMeta?.name,
    path: "organisations",
    meta: organisationsWZRguKM6YsMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "app-admin-organisations___en",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "app-admin-organisations-member___en",
    path: "member",
    meta: memberFL1zKApLrQMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/organisations/member.vue").then(m => m.default || m)
  }
]
  },
  {
    name: users9Cgf7B4uLuMeta?.name,
    path: "users",
    meta: users9Cgf7B4uLuMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users.vue").then(m => m.default || m),
    children: [
  {
    name: "app-admin-users-accesses___en",
    path: "accesses",
    meta: accessesL5sJw7QBBdMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users/accesses.vue").then(m => m.default || m)
  },
  {
    name: "app-admin-users___en",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "app-admin-users-permission___en",
    path: "permission",
    meta: permissionWD60ao0HQEMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users/permission.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "app-auth-login___en",
    path: "auth/login",
    meta: loginxj5AzlKiskMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "app-auth-password-reset___en",
    path: "auth/password-reset",
    meta: password_45resetbNFAmQM7JcMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/auth/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "app-auth-sing-up___en",
    path: "auth/sing-up",
    meta: sing_45upI9WSN6sEt8Meta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/auth/sing-up.vue").then(m => m.default || m)
  },
  {
    name: file_45transfertCEEJeGU6ShMeta?.name,
    path: "file-transfert",
    meta: file_45transfertCEEJeGU6ShMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/file-transfert.vue").then(m => m.default || m),
    children: [
  {
    name: "app-file-transfert___en",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/file-transfert/index.vue").then(m => m.default || m)
  },
  {
    name: "app-file-transfert-upload___en",
    path: "upload",
    meta: uploadqo65OiMrNvMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/file-transfert/upload.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app___en",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: mediaseDxfU3SeanMeta?.name,
    path: "medias",
    meta: mediaseDxfU3SeanMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/medias.vue").then(m => m.default || m),
    children: [
  {
    name: "app-medias___en",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/medias/index.vue").then(m => m.default || m)
  },
  {
    name: "app-medias-videos___en",
    path: "videos",
    meta: videosIVFkStdGmFMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/medias/videos.vue").then(m => m.default || m)
  }
]
  },
  {
    name: monitoringVSXmF0u7w3Meta?.name,
    path: "monitoring",
    meta: monitoringVSXmF0u7w3Meta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring.vue").then(m => m.default || m),
    children: [
  {
    name: "app-monitoring___en",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring/index.vue").then(m => m.default || m)
  },
  {
    name: "app-monitoring-live___en",
    path: "live",
    meta: liveGGrSW6StDMMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring/live.vue").then(m => m.default || m)
  },
  {
    name: "app-monitoring-recorded___en",
    path: "recorded",
    meta: recordedJt3FZD0AYrMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring/recorded.vue").then(m => m.default || m)
  }
]
  },
  {
    name: organisationscf0RPpRFDtMeta?.name,
    path: "organisations",
    meta: organisationscf0RPpRFDtMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "app-organisations-id___en",
    path: ":id()",
    meta: _91id_93cVynUEpbXvMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: "app-organisations___en",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/organisations/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: appYQ1M9TLrvoMeta?.name,
    path: "/fr/app",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: accountw1Y9owfDFiMeta?.name,
    path: "account",
    meta: accountw1Y9owfDFiMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/account.vue").then(m => m.default || m),
    children: [
  {
    name: "app-account___fr",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/account/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: admingeQBeoGEK7Meta?.name,
    path: "admin",
    meta: admingeQBeoGEK7Meta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "app-admin-database___fr",
    path: "database",
    meta: databaseimKSoMZGAtMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/database.vue").then(m => m.default || m)
  },
  {
    name: "app-admin___fr",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/index.vue").then(m => m.default || m)
  },
  {
    name: mediasT4SrFfL0szMeta?.name,
    path: "medias",
    meta: mediasT4SrFfL0szMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/medias.vue").then(m => m.default || m),
    children: [
  {
    name: "app-admin-medias___fr",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/medias/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app-admin-monitoring___fr",
    path: "monitoring",
    meta: monitoringirfjGcWZYPMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/monitoring.vue").then(m => m.default || m)
  },
  {
    name: organisationsWZRguKM6YsMeta?.name,
    path: "organisations",
    meta: organisationsWZRguKM6YsMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "app-admin-organisations___fr",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "app-admin-organisations-member___fr",
    path: "member",
    meta: memberFL1zKApLrQMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/organisations/member.vue").then(m => m.default || m)
  }
]
  },
  {
    name: users9Cgf7B4uLuMeta?.name,
    path: "users",
    meta: users9Cgf7B4uLuMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users.vue").then(m => m.default || m),
    children: [
  {
    name: "app-admin-users-accesses___fr",
    path: "accesses",
    meta: accessesL5sJw7QBBdMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users/accesses.vue").then(m => m.default || m)
  },
  {
    name: "app-admin-users___fr",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "app-admin-users-permission___fr",
    path: "permission",
    meta: permissionWD60ao0HQEMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/admin/users/permission.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "app-auth-login___fr",
    path: "auth/login",
    meta: loginxj5AzlKiskMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "app-auth-password-reset___fr",
    path: "auth/password-reset",
    meta: password_45resetbNFAmQM7JcMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/auth/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "app-auth-sing-up___fr",
    path: "auth/sing-up",
    meta: sing_45upI9WSN6sEt8Meta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/auth/sing-up.vue").then(m => m.default || m)
  },
  {
    name: file_45transfertCEEJeGU6ShMeta?.name,
    path: "file-transfert",
    meta: file_45transfertCEEJeGU6ShMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/file-transfert.vue").then(m => m.default || m),
    children: [
  {
    name: "app-file-transfert___fr",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/file-transfert/index.vue").then(m => m.default || m)
  },
  {
    name: "app-file-transfert-upload___fr",
    path: "upload",
    meta: uploadqo65OiMrNvMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/file-transfert/upload.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app___fr",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: mediaseDxfU3SeanMeta?.name,
    path: "medias",
    meta: mediaseDxfU3SeanMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/medias.vue").then(m => m.default || m),
    children: [
  {
    name: "app-medias___fr",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/medias/index.vue").then(m => m.default || m)
  },
  {
    name: "app-medias-videos___fr",
    path: "videos",
    meta: videosIVFkStdGmFMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/medias/videos.vue").then(m => m.default || m)
  }
]
  },
  {
    name: monitoringVSXmF0u7w3Meta?.name,
    path: "monitoring",
    meta: monitoringVSXmF0u7w3Meta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring.vue").then(m => m.default || m),
    children: [
  {
    name: "app-monitoring___fr",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring/index.vue").then(m => m.default || m)
  },
  {
    name: "app-monitoring-live___fr",
    path: "live",
    meta: liveGGrSW6StDMMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring/live.vue").then(m => m.default || m)
  },
  {
    name: "app-monitoring-recorded___fr",
    path: "recorded",
    meta: recordedJt3FZD0AYrMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/monitoring/recorded.vue").then(m => m.default || m)
  }
]
  },
  {
    name: organisationscf0RPpRFDtMeta?.name,
    path: "organisations",
    meta: organisationscf0RPpRFDtMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "app-organisations-id___fr",
    path: ":id()",
    meta: _91id_93cVynUEpbXvMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: "app-organisations___fr",
    path: "",
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/app/organisations/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "index___en",
    path: "/",
    meta: index2Z9I93O6EjMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: index2Z9I93O6EjMeta || {},
    component: () => import("D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/pages/index.vue").then(m => m.default || m)
  }
]