import type { Referential } from "~/parts/referentials";

declare global {

    interface Access extends Referential {
        id: number;
        name: string;
        code: string;
        permissions: Array<number>
    }

    interface Permission extends Referential {
        id?: number;
        code: string;
        name: string;
        level: string;
        parentId: number;
    }
}

const PermissionsProvider = {
    SuperAdministrator : "SuperAdministrator",
    DatabaseAdministrator : "DatabaseAdministrator",
    UserAdministrator : "UserAdministrator",
    MediasViewer: "MediasViewer",
    MediasAdministrator : "MediasAdministrator",
    OrganisationAdministrator : "OrganisationAdministrator",
    Organisation: "Organisation",
    MonitoringViewer: "MonitoringViewer",
    MonitoringAdministrator: "MonitoringAdministrator"
}

export { PermissionsProvider }
