import { type IHttpConnectionOptions } from "@microsoft/signalr";
import EWebSocket from "~/parts/core/webSocket/eWebSocket";
import type { PublicRuntimeConfig } from '@nuxt/schema';

class NotificationWs extends EWebSocket {
    constructor(config: PublicRuntimeConfig, options?: IHttpConnectionOptions) {
        const url = `${config.API_URL}${config.websocket.notificationKey}`;
        super(url, options);
    }

    override initHandlers() {
        this.addHandler("NotifyUser", async message => {
            await Swal.fire({ icon: "info", text: message, position: "top-end" });
        });
    }
}

export default NotificationWs;
