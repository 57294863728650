import NotificationWs from "~/parts/core/notifications/notificationWs";
import { type IHttpConnectionOptions } from "@microsoft/signalr";

export default defineNuxtPlugin(async nuxtApp => {
    const config = useRuntimeConfig();
    const notificationWs = new NotificationWs(config.public);
    const { onSignOut, onSignedIn } = await useEAuth();

    const startsWss = async () => {
        const { tokens, loggedIn } = await useEAuth();
        if (tokens.value?.token && loggedIn.value) {
            const wsOption: IHttpConnectionOptions = { accessTokenFactory: () => tokens.value.token as string };
            notificationWs.start(wsOption);
        }
    };

    await startsWss();

    onSignedIn(() => startsWss());
    onSignOut(async () => {
        await notificationWs.stop();
    });

    nuxtApp.vueApp.provide("$notificationWs", notificationWs);
});
