import validate from "D:/a/1/s/ClientWebApp.v1/node_modules/nuxt/dist/pages/runtime/validate.js";
import e_45auth_45global from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/middleware/eAuth.global.ts";
import manifest_45route_45rule from "D:/a/1/s/ClientWebApp.v1/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  e_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("D:/a/1/s/ClientWebApp.v1/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}