import EService from '~/parts/core/services/eService';
const allServicesModules = import.meta.glob("~/parts/**/*.referential.service.ts", { import: "default" });
import { type Referential, type ReferentialInfo } from "~/parts/referentials";
import useReferentialsStore from "~/stores/referentials/referentialsStore";
import { storeToRefs } from 'pinia';


const EReferentialGetAllPath = "referential/getall/?typeName=";

class ReferentialsService extends EService {

    keyBase: string;

    constructor(useApiKey: boolean = false) {
        super(useApiKey);
        this.keyBase = EReferentialGetAllPath;
    }

    async init() {
        const modules = Object.entries(allServicesModules).map(async module => await module[1]());
        const services = await Promise.all(modules);
        services.forEach((service: any) => {
            const referentialsInfos: Array<ReferentialInfo> = service.getReferentialsInfos();
            referentialsInfos.forEach(referentialInfo => this.registerReferentialAsync(referentialInfo));
        });
    }

    async registerReferentialAsync(referentialInfo: ReferentialInfo) {
        const urlKey = this.keyBase + referentialInfo.typeName;
        const info: ReferentialInfo = {
            ...referentialInfo,
            key: urlKey,
        };
        if (referentialInfo.typeName.startsWith("~local")) {
            info.data = referentialInfo.localData;
            info.key = referentialInfo.typeName.toString();
        } else {
            info.loadDataAsync = async () => {
                const key = this.keyBase + referentialInfo.typeName;
                const Referentials = await this.http.get<Array<Referential>>(key);
                return Referentials;
            }
        }

        const store = useReferentialsStore();
        await store.registerReferentialAsync(info);
    }

    getReferentialDisplayableFields(typeName: string) {
        const urlKey = this.keyBase + typeName;
        const { getReferentialMetaData } = storeToRefs(useReferentialsStore());
        const fields = getReferentialMetaData.value(urlKey);
        return ref(fields);
    }

    getReferentialHelpers(typeName: string) {
        const urlKey = this.keyBase + typeName;
        const { getHelpers } = storeToRefs(useReferentialsStore());
        const helpers = getHelpers.value(urlKey);
        return ref(helpers);
    }

    async getAllAsync<T extends Referential = Referential>(typeName: string, forceReload: boolean = false) {
        const key = typeName.startsWith("~local") ? typeName : this.keyBase + typeName;
        const { getReferentialAsync } = storeToRefs(useReferentialsStore());
        return ref( await getReferentialAsync.value(key, forceReload) as Array<T>);
    }

    async getAsync<T extends Referential = Referential>(typeName: string, id: number | string) {
        const data = await this.getAllAsync<T>(typeName);
        return data.value?.find(r => r.id == id) ?? null;
    }

    async saveAsync<T extends Referential = Referential>(typeName: string, referential: T) {
        const response = await this.http.post<T, T>(`referential/save/?typeName=${typeName}`, referential);
        return response;
    }

    async deleteAsync(typeName: string, referential: Referential) {
        const response = await this.http.remove(`referential/delete/?typeName=${typeName}`, referential);
        return response;
    }
}

export default ReferentialsService;
export const ReferentialGetAllPath = EReferentialGetAllPath;
