import { type Ref } from "vue-demi";
import { storeToRefs } from "pinia";
import {
    type RouteRecordRaw, type RouteRecordName
} from "vue-router";
import { eMenuStore } from "~/stores/menu/menu.store";
import {
    type EMenuItemBase, type EMenuItem, type BreadcrumbsItem
} from "~~/ClientWebAppSrc/parts/menu/types";
import { type RuntimeConfig } from "nuxt/schema";
import type { NuxtApp } from "#app";
import { type Router, type RouteLocationNormalizedLoaded } from "vue-router";

enum MenuDisplayMode {
    list = "list",
    tile = "tiles",
    button = "buttons",
    breadcrumb = "breadcrumb"
}

function findRecursive(list: EMenuItemBase[], condition: (e: EMenuItemBase) => boolean): EMenuItemBase | undefined | null {
    const element = list.find(condition);
    if (!element) {
        for (let i = 0, menuItem: EMenuItemBase; menuItem = list[i]; ++i) {
            if (menuItem.subMenuItems && menuItem.subMenuItems.length > 0) {
                const el = findRecursive(menuItem.subMenuItems, condition);
                if (el) {
                    return el;
                }
            }
        }
    }
    return element;
}

function routeHasAuthMiddleware(route: RouteRecordRaw, config: RuntimeConfig): boolean {
    const middleware = route.meta?.middleware;
    return !!middleware && (middleware instanceof String && (middleware == "auth")
        || middleware instanceof Array && (middleware as Array<any>).includes("e-auth"))
        || !!route.meta?.auth
        || !route.meta?.auth && config.public.auth?.globalAppMiddleware.isEnabled;
}

async function getMenuItem(route: RouteRecordRaw, menuItems: EMenuItem[]): Promise<EMenuItem> {
    const routeName = (route.name ?? route.children?.find(n => n.path === "")?.name) as string;
    const routIsCurrentlocal = routeName.endsWith(nuxtAppContext.$i18n.locale.value);
    const { hasAccessAsync } = useCurrentUser();
    const config = useRuntimeConfig();
    const routePermissions = route.meta?.permissions as string ?? route.meta?.auth ?? config.public.auth?.globalAppMiddleware.isEnabled;
    const menuData = { ...route.meta?.menuItem as EMenuItem };
    menuData.route = { name: routeName } as RouteRecordRaw;
    let menuItem = findRecursive(menuItems, mi => mi.name == menuData.name);
    const hasAccessOnRoute = !routeHasAuthMiddleware(route, config) || await hasAccessAsync(routePermissions);
    if (!menuItem && hasAccessOnRoute && routIsCurrentlocal) {
        menuItem = menuData;
        menuItem.subMenuItems = [];
    } else if (hasAccessOnRoute && routIsCurrentlocal) {
        menuItem = Object.assign(menuItem as EMenuItem, menuData);
    } else {
        menuItem = null;
    }

    if (!!menuItem && hasAccessOnRoute && route.children?.length) {
        const childrenRoutes = route.children.filter(c => c.name != routeName);
        const promise = childrenRoutes.reduce(async (out: Promise<EMenuItem[]>, current: RouteRecordRaw): Promise<EMenuItem[]> => {
            const item = !!current.meta?.menuItem ? await getMenuItem(current, menuItems) : null;
            const accu = await out;
            if (item) {
                item.parentName = menuItem?.name;
                accu.push(item);
            }
            return Promise.resolve(accu);
        }, Promise.resolve([]));
        const subMenuItems = <EMenuItem[]>await promise;
        if (subMenuItems) {
            menuItem.subMenuItems = menuItem.subMenuItems || [];
            const allSubs = menuItem.subMenuItems.concat(subMenuItems) as EMenuItem[];
            menuItem.subMenuItems = allSubs?.sort((mi1, mi2) => mi1.displayOrder - mi2.displayOrder);
        }
    }

    return menuItem as EMenuItem;
}

async function getRouteMenuRecursive(routes: readonly RouteRecordRaw[], menuList: EMenuItem[]): Promise<EMenuItem[]> {
    const items = routes.reduce(async (accu: Promise<EMenuItem[]>, currentRoute): Promise<EMenuItem[]> => {
        const routeHasMetaMenu = !!currentRoute.meta?.menuItem;
        const menuItems = await accu;
        const menuItem = routeHasMetaMenu ? await getMenuItem(currentRoute, menuItems) : null;
        if (menuItem) {
            menuItems.push(menuItem);
        }
        if (!routeHasMetaMenu && !!currentRoute.children?.length) {
            return await getRouteMenuRecursive(currentRoute.children, menuItems);
        }
        return Promise.resolve(menuItems);
    }, Promise.resolve(menuList));
    return <EMenuItem[]>await items;
}

async function getRoutesMenuItems(routes: readonly RouteRecordRaw[], menuList: EMenuItem[]): Promise<EMenuItem[]> {
    const menuItems = await getRouteMenuRecursive(routes, menuList);

    return menuItems.sort((a, b) => a.displayOrder - b.displayOrder);
}

let nuxtAppContext: NuxtApp;
let router: Router;
async function refreshMenuItems(nuxtApp: NuxtApp) {
    nuxtAppContext = nuxtApp;
    router = useRouter();
    const routes = router.options.routes;
    const menuItems = await getRoutesMenuItems(routes, []);
    eMenuStore().setMenuItems(menuItems);
}

function itemObjectToArray(item: EMenuItem | undefined | null): EMenuItem[] {
    if (item && item.parentName) {
        const { menuItems } = getMenuItems();
        const parent = findRecursive(menuItems.value, m => m.name == item.parentName) as EMenuItem;
        return [...itemObjectToArray(parent), item];
    }
    else if (!item)
        return [];

    return [item];
}

function getMenuItems(): { menuItems: Ref<EMenuItem[]>, customBreadcrumbsItems: Ref<BreadcrumbsItem[]> } {
    const { menuItems, customBreadcrumbsItems } = storeToRefs(eMenuStore());
    return { menuItems, customBreadcrumbsItems };
}

function findMenuItemByRouteRecursive(list: EMenuItem[], routeName: string): EMenuItem | undefined | null {
    const element = list.find(m => (typeof m.route == "string" ? m.route : m.route.name) == routeName);
    if (!element) {
        const encestorElement = list.find(m => {
            const localeRouteBase = useRouteBaseName();
            const routeBaseName = localeRouteBase(m.route as unknown as RouteLocationNormalizedLoaded);
            return routeBaseName && routeName.includes(routeBaseName);
        });
        const subList = encestorElement?.subMenuItems
        return subList ? findMenuItemByRouteRecursive(subList, routeName) : null;
    }
    return element;
}

function getCurrentRouteMenu(): EMenuItem | undefined | null {
    const route = router.currentRoute.value;
    const currentRouteName = route.name as string;
    const { menuItems } = getMenuItems();
    const menu = menuItems.value.find(m => (typeof m.route == "string" ? m.route : m.route.name) == currentRouteName);
    return menu ?? findMenuItemByRouteRecursive(menuItems.value, currentRouteName);
}

function createBreadcrumbsItem(menuItem: EMenuItem): BreadcrumbsItem {
    const route = router.currentRoute.value;
    return {
        disabled: menuItem.name !== "root" && !menuItem.subMenuItems?.length && route.name != "index",
        title: menuItem.title,
        localeTitleKey: menuItem.localeTitleKey,
        link: false,
        to: menuItem.route,
        subMenuItems: menuItem.subMenuItems?.map(sm => createBreadcrumbsItem(sm)),
        name: menuItem.name,
        icon: menuItem.icon,
        isDynamic: menuItem.isDynamic
    } as BreadcrumbsItem;
}

function getBreadcrumbsItems(): Ref<BreadcrumbsItem[]> {
    const route = router.currentRoute.value;
    const items = itemObjectToArray(getCurrentRouteMenu());
    const currentRouteMatched = route.matched.map(r => r.name);
    const { menuItems, customBreadcrumbsItems } = getMenuItems();
    const otherItems = customBreadcrumbsItems.value.filter(bm => currentRouteMatched.includes(typeof bm.to == "string" ? bm.to : bm.to.name as RouteRecordName));
    const localePath = useLocalePath();
    const rootItem: EMenuItem = {
        name: "root", displayOrder: 0, route: localePath({ name: "app" })
    };

    rootItem.subMenuItems = menuItems.value;
    const breadcrumbsItems = [rootItem, ...items].map(v => {
        return createBreadcrumbsItem(v);
    }).concat(otherItems);
    return ref(breadcrumbsItems);
}

function updateBreadcrumb(data: EMenuItem, properties: string[] = []) {
    const menuStore = eMenuStore();
    const { menuItems, customBreadcrumbsItems } = getMenuItems();
    let menuItem = findRecursive(menuItems.value, i => i.name == data.name);
    menuItem = menuItem ?? findRecursive(customBreadcrumbsItems.value, i => i.name == data.name);
    if (!!menuItem) {
        Object.assign(menuItem, data);
    }
    else {
        menuStore.setCustomBreadcrumbsItems([
            ...customBreadcrumbsItems.value,
            ...itemObjectToArray(data).map(i => createBreadcrumbsItem(i))
        ]);
    }
}


export {
    MenuDisplayMode,
    refreshMenuItems, getBreadcrumbsItems,
    updateBreadcrumb, getMenuItems
}
