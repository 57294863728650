<template>
    <NuxtLayout name="error" :error="error">
    </NuxtLayout>
</template>
<script setup lang="ts">
    import type { NuxtError } from '#app';

    //#region Props
    const props = defineProps({
        error: {
            type: Object as PropType<NuxtError>,
            default: null
        }
    });
    //#endregion

    const errorHeaderTitleTemplate = `${ props.error.statusCode } - ${props.error.message} | %s`;
    useHead({
        titleTemplate: errorHeaderTitleTemplate
    });
</script>