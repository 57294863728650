import { defineStore } from 'pinia';

export const loadingStore = defineStore({
    id: 'loading',
    state: () => ({
        loaderCount: 0,
        isVisible: false
    }),
    getters: {
        loadingIsVisible: state => state.isVisible,
        getLoaderCount: state => state.loaderCount
    },
    actions: {
        displayLoading() {
            this.isVisible = true;
            this.loaderCount++;
        },
        hideLoading(hideAll: boolean = false) {
            this.loaderCount--;
            if (this.loaderCount <= 0 || hideAll)
                this.isVisible = false;
            if (hideAll)
                this.loaderCount = 0;

        }
    }
});