import EWebSocket from "~/parts/core/webSocket/eWebSocket";
import ReferentialsService from "~/parts/referentials/referentialsService";
import type { PublicRuntimeConfig } from '@nuxt/schema';
import { type IHttpConnectionOptions } from "@microsoft/signalr";

class ReferentialWs extends EWebSocket {
    referentialsService: ReferentialsService;

    constructor(config: PublicRuntimeConfig, options?: IHttpConnectionOptions) {
        const url = `${config.API_URL}${config.websocket.referentialKey}`;
        super(url, options);
        this.referentialsService = new ReferentialsService();
    }

    override initHandlers() {
        this.addHandler("ReferentialUpdatedAsync", async referentialTypeName => {
            await this.referentialsService.getAllAsync(referentialTypeName, true);
        });
    }
}

export default ReferentialWs;
