import {
    HubConnectionBuilder, LogLevel, HubConnection,
    type IHttpConnectionOptions, HubConnectionState
} from "@microsoft/signalr";

class EWebSocket {
    url: string;
    options: IHttpConnectionOptions
    connectionBuilder: HubConnectionBuilder;
    connection: HubConnection | null = null;

    constructor(url: string, options?: IHttpConnectionOptions) {
        this.url = url;
        this.options = options ?? {};
        this.connectionBuilder = new HubConnectionBuilder()
            .withUrl(url, this.options)
            .configureLogging(LogLevel.Warning);
    }

    async start(options?: IHttpConnectionOptions) {
        if (!!options) {
            this.connectionBuilder = this.connectionBuilder.withUrl(this.url, Object.assign(this.options, options));
        }

        if (this.connection == null || !!options) {
            this.connection = this.connectionBuilder.build();
            this.initHandlers();
        }

        if (this.connection.state == HubConnectionState.Disconnected)
            return await this.connection.start();

        this.connection.onclose(this.onClose);
        return Promise.resolve();
    }

    async stop() {
        return await this.connection?.stop();
    }

    initHandlers() { }
    addHandler(methodName: string, handler: (...args: any[]) => void) {
        this.connection?.on(methodName, handler);
    }

    onClose() {
        const waitFiveSeconds = 5000;
        const self = this;
        setTimeout(async function () {
            await self.start();
        }, waitFiveSeconds);
    }
}

export default EWebSocket;
