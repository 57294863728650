import PermissionsConstantsProvider from "~/parts/users/permissions/permissionsConstantsProvider";
import {
    ReferentialGetAllPath
} from "~/parts/referentials/referentialsService";
import {
    type ReferentialInfo, type Referential, type ReferentialHelpersFunctionType
} from "~/parts/referentials";
import { type ColumnInfo } from "~/parts/core";

export declare interface ReferentialStoreState {
    referentials: {
        [key: string]: ReferentialInfo
    }
}

export declare interface ReferentialStoreGetters {
    [key: string]: any;
    getReferentialAsync: (state: ReferentialStoreState) => (regionName: string, force: boolean) => Promise<Array<Referential> | null>;
    getReferentialMetaData: (state: ReferentialStoreState) => (regionName: string) => Array<ColumnInfo> | null;
    getHelpers: (state: ReferentialStoreState) => (regionName: string) => ReferentialHelpersFunctionType | null;
}

export declare interface ReferentialStoreActions {
    registerReferentialAsync: (data: ReferentialInfo) => Promise<void>;
    initPermissions: (permissions: Array<Permission>) => void;
}

function addUpdateReferential(state: ReferentialStoreState, info: ReferentialInfo) {
    let dataInfo = state.referentials[info.key as string] ?? {};
    dataInfo.data = info.data ?? dataInfo.data;
    dataInfo.metaData = info.metaData ?? dataInfo.metaData;
    dataInfo.metaData = info.metaData ?? dataInfo.metaData;
    dataInfo.localData = info.localData ?? dataInfo.localData;
    dataInfo.typeName = info.typeName ?? dataInfo.typeName;
    dataInfo.allowFastCreation = info.allowFastCreation ?? dataInfo.allowFastCreation;
    dataInfo.isLoading = info.isLoading;
    dataInfo.loadDataAsync = info.loadDataAsync ?? dataInfo.loadDataAsync;
    dataInfo.lastUpdateDate = info.lastUpdateDate ?? dataInfo.lastUpdateDate;
    dataInfo.helpers = info.helpers ?? dataInfo.helpers;
    state.referentials[info.key as string] = dataInfo;
}

function addPermissions(state: ReferentialStoreState, permissions: Array<Permission>) {
    const key = `${ReferentialGetAllPath}${PermissionsConstantsProvider.PermissionTypeName}`;
    let dataInfo = state.referentials[key] || {};
    dataInfo.data = [...(dataInfo.data ?? []), ...(permissions ?? [])];
    dataInfo.lastUpdateDate = Date.now();
    state.referentials[key] = dataInfo;
}

export default defineStore<string, ReferentialStoreState, ReferentialStoreGetters, ReferentialStoreActions>("referentials", {
    state: (): ReferentialStoreState => ({
        referentials: {}
    }),
    getters: {
        getReferentialAsync: (state: ReferentialStoreState) => {
            return async (key: string, force: boolean = false) => {
                let loader = state.referentials[key];
                if (loader) {
                    const lastUpdateDate = loader.lastUpdateDate;
                    const duration = !!lastUpdateDate ? Math.abs(Date.now() - lastUpdateDate) : 0;
                    const needToLoadData = !lastUpdateDate
                        || !loader.data && !loader.isLoading
                        || duration > (1000 * 60 * 30)
                        || force;

                    if (needToLoadData && loader.loadDataAsync) {
                        await loader.loadDataAsync();
                    } else if (loader.isLoading) {
                        while (loader.isLoading) {
                            await new Promise(resolve => setTimeout(resolve, 1000));
                        }
                    }
                    return loader.data;
                }
                return null;
            }
        },
        getReferentialMetaData: (state: ReferentialStoreState) => {
            return (key: string) => {
                return state.referentials[key]?.metaData ?? null;
            }
        },
        getHelpers: (state: ReferentialStoreState) => (key: string) => {
            return state.referentials[key]?.helpers ?? null;
        }
    },
    actions: {
        async registerReferentialAsync(data: ReferentialInfo) {
            if (data.key && !this.referentials[data.key]) {
                const loader: ReferentialInfo = {
                    ...data,
                    lastUpdateDate: Date.now(),
                    loadDataAsync: !data.loadDataAsync ? undefined : async () => {
                        addUpdateReferential(this, {
                            key: data.key,
                            isLoading: true
                        } as ReferentialInfo);
                        const response = data.loadDataAsync ? await data.loadDataAsync() : null;
                        addUpdateReferential(this, {
                            key: data.key,
                            data: response,
                            isLoading: false,
                            lastUpdateDate: Date.now()
                        } as ReferentialInfo);
                    }
                };
                addUpdateReferential(this, loader);

                if (data.loadOnInit && loader.loadDataAsync) {
                    await loader.loadDataAsync();
                }
            }
        },
        initPermissions(permissions) {
            addPermissions(this, permissions);
        }
    }
});
