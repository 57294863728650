import { eApi, type EApi } from "~/parts/api";

class EService {
    http: EApi;

    constructor(useApiKey: boolean = false) {
        this.http = eApi(useApiKey);
    }

    serializeArrayQuery<T>(key: string, queryValues: Array<T>): string {
        return queryValues.map(v => `${key}=${v}`).join("&");
    }
}

export default EService;
