import type { NuxtApp } from "#app";
import { loadPartsLocaleMessages } from "~/parts/core/localization";
import { refreshMenuItems } from "~/parts/menu";
import { type RouteLocation } from "vue-router";

const loadedPartsLocale: Array<string> = [];

function getTitleTemplate(i18n: I18nInstanceType,  route: RouteLocation) {
    const { t } = useELocale(i18n);
    const metaTitle = !!route.meta?.title ? `${route.meta.title} | %s` : "%s";
    const localTitle = !!route.meta?.titleKey ? `${t(route.meta.titleKey as string)} | %s` : metaTitle;
    const headerTitleTemplate = route.meta?.uniqueTitle ?? localTitle;
    return headerTitleTemplate as string;
}

export default defineNuxtPlugin(async nuxtApp => {

    const nuxtAppContext = nuxtApp as NuxtApp;
    const loadedLocale = await loadPartsLocaleMessages(nuxtAppContext.$i18n.locale.value, nuxtAppContext);
    loadedPartsLocale.push(loadedLocale);

    const router = useRouter();
    router.beforeEach((to, _) => {
        useHead({
            titleTemplate: getTitleTemplate(nuxtApp.$i18n as I18nInstanceType, to)
        });
    });

    nuxtAppContext.$i18n.onBeforeLanguageSwitch = async (_, newLocale, __, context) => {
        const nuxtApp = context as NuxtApp;
        if (!loadedPartsLocale.includes(newLocale)) {
            const switchLoadedLocale = await loadPartsLocaleMessages(newLocale, nuxtApp);
            loadedPartsLocale.push(switchLoadedLocale);
        }
    };

    nuxtApp.hook("i18n:localeSwitched", async _ => {
        await refreshMenuItems(nuxtApp as NuxtApp);
    });
});
