import { getCurrentUser } from "~~/ClientWebAppSrc/parts/auth";

function getTrimedBy(source: string, startAndOrEnd: string, end: string | null = null) {
    const replaceStart = new RegExp(`^${startAndOrEnd}`, "g");
    const replaceEnd = new RegExp(`${end ?? startAndOrEnd}$`, "g");
    let result = source.replace(replaceStart, "");
    result = result.replace(replaceEnd, "");
    return result;
}

function checkIfDirectChild(requiredPermissionLevel: string, userPermissionLevels: string): boolean {
    const splitedRequiredLevel = requiredPermissionLevel.split("-");
    const requiredPermissionLevelInt = Number.parseInt(splitedRequiredLevel[splitedRequiredLevel.length - 1].replace("^", ""));
    const userPermissionSplitedLevels = userPermissionLevels.replace(`${requiredPermissionLevel}-`, "").split("-");
    const userPermissionLevel = userPermissionSplitedLevels[userPermissionSplitedLevels.length - 1].replace("^", "");
    const userPermissionLevelInt = Number.parseInt(userPermissionLevel);
    const minLevel = requiredPermissionLevelInt * 1000;
    const maxLevel = requiredPermissionLevelInt * 1000 + 1000;
    return userPermissionLevelInt > minLevel && userPermissionLevelInt < maxLevel;
}

function checkIfSiblings(requiredPermissionLevel: string, userPermissionLevel: string): boolean {
    const splitedRequiredLevel = requiredPermissionLevel.split("-");
    const splitedUserLevel = userPermissionLevel.split("-");
    for (var i = 0; i < Math.max(splitedRequiredLevel.length, splitedUserLevel.length); i++) {
        const requiredLevel = Number.parseInt(splitedRequiredLevel[i]);
        const userLevel = Number.parseInt(splitedUserLevel[i]);
        if (requiredLevel != userLevel) {
            const diff = Math.abs(requiredLevel - userLevel);
            return diff >= 0 && diff < 1000;
        }
    }
    return false;
}

function permissionHierachyCheckPredicat(
    permissions: Array<Permission> | null,
    requestedPermission: string,
    userPermissions?: Array<Permission>) {
    const requiredPermision = permissions?.find(p => p.code == getTrimedBy(requestedPermission, "<", ">"));
    return !!requiredPermision && !!userPermissions && userPermissions.some(up =>
        requiredPermision.level.includes(up.level) ||
        requestedPermission.endsWith(">") && checkIfDirectChild(requiredPermision.level, up.level) ||
        requestedPermission.startsWith("<") && requestedPermission.endsWith(">") && checkIfSiblings(requiredPermision.level, up.level)
    );
}

async function checkPermissionHierachy(
    requiredPermissions: Array<string>,
    applyAndOperator: boolean,
    userPermissions?: Array<Permission>) {
    const { default: PermissionsService } = await import("~~/ClientWebAppSrc/parts/users/permissions/permissions.referential.service");
    const permissionService = new PermissionsService();
    const permissions = await permissionService.getAllPermissions();
    return applyAndOperator && requiredPermissions.every(rp => permissionHierachyCheckPredicat(permissions, rp, userPermissions)) ||
        requiredPermissions.some(rp => permissionHierachyCheckPredicat(permissions, rp, userPermissions));
}

function permissionCheckPredicat(requiredPermission: string, userPermissions?: Array<Permission>) {
    return !!userPermissions && userPermissions.map((p: Permission) => p.code).includes(getTrimedBy(requiredPermission, "<", ">"));
}

export default () => {
    const currentUser = getCurrentUser();

    const names = computed(() =>
        [
            currentUser?.value?.firstName?.replaceAll(/ +/g, "&nbsp;"),
            currentUser?.value?.lastName?.replaceAll(/ +/g, "&nbsp;"),
            currentUser?.value?.email
        ].filter(n => !!n).map(n => (n as string))
    );
    const fullName = computed(() => names.value.slice(0, 2).join(" "));
    const initials = computed(() => names.value.slice(0, 2).map(n => n.charAt(0)).join(" ").toUpperCase());

    const isAdmin = () => {
        const userpermissions = currentUser?.value?.permissions ?? [];
        return userpermissions.some((p: Permission) => p.code == PermissionsProvider.SuperAdministrator);
    };

    const hasAccessAsync = async (requestedPermissions: string | Boolean) => {
        if (!requestedPermissions) {
            return true;
        }
        else if (!currentUser.value) {
            return false;
        }
        else if (typeof requestedPermissions == "boolean") {
            return !requestedPermissions || requestedPermissions && !!currentUser.value;
        }
        else {
            const userPermissions = currentUser?.value?.permissions,
                requiredPermissions = (requestedPermissions as string);
            const permissionWithAndOperator = requiredPermissions.includes("&");
            const requiredPermissionsArray = requiredPermissions.split(/&|\|/);
            const userHasAccess = isAdmin() ||
                permissionWithAndOperator && requiredPermissionsArray.every(rp => permissionCheckPredicat(rp, userPermissions)) ||
                !permissionWithAndOperator && requiredPermissionsArray.some(rp => permissionCheckPredicat(rp, userPermissions)) ||
                !!userPermissions && await checkPermissionHierachy(requiredPermissionsArray, permissionWithAndOperator, userPermissions);

            return userHasAccess;
        }
    };

    return { currentUser, initials, fullName, isAdmin, hasAccessAsync };
};
