import { type App } from "vue-demi";

export declare type VFilterType = (v: any, ...args: Array<any>) => any;

export declare interface VFilterDefinition {
    name: string;
    filter: VFilterType
}
export declare interface VFilter {
    [key: string]: VFilterType
}

const customFilters: Array<VFilterDefinition> = [
    {
        name: "currency",
        filter: (value: number | null, code: string = "EUR", format: string = "fr-FR") =>
            new Intl.NumberFormat(format, { style: "currency", currency: code }).format(value ?? 0)
    },
    {
        name: "localDate",
        filter: (value: Date, local: Intl.LocalesArgument = "fr-FR", opt?: Intl.DateTimeFormatOptions) =>
            value?.getHours() && value?.getMinutes() && value?.getSeconds()
                ? value.toLocaleString(local, opt)
                : value.toLocaleDateString(local, opt)
    },
    {
        name: "shortLocalDate",
        filter: (value: Date, local: Intl.LocalesArgument = "fr-FR", opt?: Intl.DateTimeFormatOptions) =>
            value?.getHours() && value?.getMinutes() && value?.getSeconds()
                ? value.toLocaleString(local, { ...opt, dateStyle: "short" })
                : value.toLocaleDateString(local, { ...opt, dateStyle: "short"})
    }
]

export function AddFilters(app: App) {
    const filters: VFilter = {};
    for (const filter of customFilters) {
        filters[filter.name] = filter.filter;
    }
    app.config.globalProperties.$filters = filters;
    app.provide("$filters", filters);
};
