import {
    isLoggedIn, loginAsync, signOut, onSignOut, getTokens,
    onSignedIn,
    type AuthResult
} from "~/parts/auth";
import { type UserAuth } from "~/parts/users";

export const useEAuth = () => {
    async function login(user: UserAuth, callbackUrl?: string): Promise<AuthResult> {
        return await loginAsync(user, callbackUrl);
    }

    const loggedIn = isLoggedIn();
    const tokens = getTokens();

    return {
        loggedIn,
        login,
        signOut,
        onSignOut,
        tokens,
        onSignedIn
    }
};
