import { defineStore } from "pinia";
import {
    type MenuStore, type EMenuItem,
    type BreadcrumbsItem, type MenuStoreGetters
} from "~~/ClientWebAppSrc/parts/menu/types";

export const eMenuStore = defineStore("menu", {
    state: (): MenuStore => ({
        menuItems: [],
        customBreadcrumbsItems: []
    }),
    getters: {
        test() { return 1; }
    } as MenuStoreGetters,
    actions: {
        setMenuItems(items: EMenuItem[]): void {
            this.menuItems = items;
        },
        setCustomBreadcrumbsItems(items: BreadcrumbsItem[]): void {
            this.customBreadcrumbsItems = items;
        }
    }
})