import revive_payload_client_4sVQNw7RlN from "D:/a/1/s/ClientWebApp.v1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "D:/a/1/s/ClientWebApp.v1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/a/1/s/ClientWebApp.v1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "D:/a/1/s/ClientWebApp.v1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "D:/a/1/s/ClientWebApp.v1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "D:/a/1/s/ClientWebApp.v1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "D:/a/1/s/ClientWebApp.v1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "D:/a/1/s/ClientWebApp.v1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "D:/a/1/s/ClientWebApp.v1/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "D:/a/1/s/ClientWebApp.v1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_Y6agwlnXZq from "D:/a/1/s/ClientWebApp.v1/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "D:/a/1/s/ClientWebApp.v1/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_AOuQ1DYzjk from "D:/a/1/s/ClientWebApp.v1/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "D:/a/1/s/ClientWebApp.v1/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "D:/a/1/s/ClientWebApp.v1/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import index_9x6MJfUQGT from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/plugins/eMenu/index.ts";
import index_Fjknzk6R4S from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/plugins/eWebSocket/index.ts";
import index_rp9LOxDW1q from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/plugins/referentials/index.ts";
import index_apqnluiwmJ from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/plugins/eLocalization/index.ts";
import helpers_n7w5kyvZJp from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/plugins/helpers.ts";
import vuetify_hvlhyNQKhy from "D:/a/1/s/ClientWebApp.v1/ClientWebAppSrc/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  plugin_AOuQ1DYzjk,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  index_9x6MJfUQGT,
  index_Fjknzk6R4S,
  index_rp9LOxDW1q,
  index_apqnluiwmJ,
  helpers_n7w5kyvZJp,
  vuetify_hvlhyNQKhy
]